const normalizeMiddleware = store => next => action => {
  const { payload, meta } = action

  return next({
    ...action,
    payload: payload || {},
    meta: meta || {},
  })
}

export default normalizeMiddleware
