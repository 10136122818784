import * as endpoints from './endpoints'

export function login(body) {
  return fetch(endpoints.login(), {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      contentType: 'application/json',
    },
  })
}

export function signup(body) {
  return fetch(endpoints.signup(), {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      contentType: 'application/json',
    },
  })
}
