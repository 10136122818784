const errorMiddleware = store => next => action => {
  next(action)

  const { payload, meta } = action

  if (meta.error) {
    throw new Error(payload)
  }
}

export default errorMiddleware
