import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

import { loginRequested } from '../actions/login.actions'
import { SIGNUP_SUCCEEDED } from '../actions/signup.actions'

const loginOnSignupEpic = action$ =>
  action$.pipe(
    ofType(SIGNUP_SUCCEEDED),
    mergeMap(action =>
      of(
        loginRequested({
          email: action.payload.email,
          password: action.payload.password,
        }),
      ),
    ),
  )

export default loginOnSignupEpic
