export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const loginRequested = loginData => ({
  type: LOGIN_REQUESTED,
  payload: loginData,
})

export const loginSucceeded = loginSuccessResponse => ({
  type: LOGIN_SUCCEEDED,
  payload: loginSuccessResponse,
  meta: {
    response: true,
  },
})

export const loginFailed = loginFailedResponse => ({
  type: LOGIN_FAILED,
  payload: loginFailedResponse,
  meta: {
    error: true,
  },
})
