export const SIGNUP_REQUESTED = 'SIGNUP_REQUESTED'
export const SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED'
export const SIGNUP_FAILED = 'SIGNUP_FAILED'

export const signupRequested = signupData => ({
  type: SIGNUP_REQUESTED,
  payload: signupData,
})

export const signupSucceeded = signupSuccessResponse => ({
  type: SIGNUP_SUCCEEDED,
  payload: signupSuccessResponse,
  meta: {
    response: true,
  },
})

export const signupFailed = signupFailedResponse => ({
  type: SIGNUP_FAILED,
  payload: signupFailedResponse,
  meta: {
    error: true,
  },
})
