import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  SIGNUP_REQUESTED,
  signupSucceeded,
  signupFailed,
} from '../actions/signup.actions'
import { signup } from '../../ajax'

const signupEpic = action$ =>
  action$.pipe(
    ofType(SIGNUP_REQUESTED),
    mergeMap(async action => await signup(action.payload)),
    mergeMap(result => of(result.json())),
    mergeMap(data => of(signupSucceeded(data))),
    catchError(error => of(signupFailed(error))),
  )

export default signupEpic
