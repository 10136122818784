import { combineEpics } from 'redux-observable'

import loginOnSignupEpic from './login-on-signup.epic'
import loginEpic from './login.epic'
import signupEpic from './signup.epic'

export const combinedEpics = combineEpics(
  loginOnSignupEpic,
  loginEpic,
  signupEpic,
)
