import React from 'react'
import { connect } from 'react-redux'

import Lobby from './Lobby'
import selectIsAuthenticated from '../redux/selectors/is-authenticated.selector'

const mapStateToProps = state => ({
  isAuthenticated: selectIsAuthenticated(state),
})

function App(props) {
  if (!props.isAuthenticated) {
    return <Lobby />
  }
  return <p>HomeScreenPlaceholder</p>
}

export default connect(mapStateToProps)(App)
