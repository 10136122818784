import { object, string, ref } from 'yup'

import passwordSchema from './password.schema'
import emailSchema from './email.schema'

export default object().shape({
  email: emailSchema.required('Required'),
  password: passwordSchema.required('No password provided.'),
  passwordConfirmation: string()
    .oneOf([ref('password')], 'Passwords must match')
    .required('No confirmed password'),
})
