import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import { login } from '../../ajax'
import {
  LOGIN_REQUESTED,
  loginSucceeded,
  loginFailed,
} from '../actions/login.actions'

const loginEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_REQUESTED),
    mergeMap(async action => await login(action.payload)),
    mergeMap(response => of(response.json())),
    mergeMap(data => of(loginSucceeded(data))),
    catchError(error => of(loginFailed(error))),
  )

export default loginEpic
