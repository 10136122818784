import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import Login from './Login'
import Signup from './Signup'

const Lobby = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Redirect to="/login" />
    </Switch>
  )
}

export default Lobby
