import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Form, withFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { signupRequested } from '../redux/actions/signup.actions'
import signupSchema from '../schemas/signup.schema'

const mapDispatchToProps = {
  signupRequested,
}

const formik = {
  mapPropsToValues: () => ({
    email: '',
    password: '',
    passwordConfirmation: '',
  }),
  handleSubmit: (values, { props }) => {
    props.signupRequested(values)
  },
  validationSchema: signupSchema,
}

const Signup = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
  } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid item>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="text"
            value={values.email}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={errors.email && touched.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={values.password}
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            error={errors.password && touched.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={values.passwordConfirmation}
              name="passwordConfirmation"
              label="Confirm Password"
              type="password"
              error={
                errors.passwordConfirmation && touched.passwordConfirmation
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button color="primary" type="submit">
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withFormik(formik),
)

export default enhance(Signup)
