import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Form, withFormik } from 'formik'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { loginRequested } from '../redux/actions/login.actions'
import loginSchema from '../schemas/login.schema'

const mapDispatchToProps = dispatch => {
  return {
    authenticate: payload => dispatch(loginRequested(payload)),
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
  },
}))

const formik = {
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  handleSubmit: (values, { props }) => {
    props.authenticate(values)
  },
  validationSchema: loginSchema,
}

const Login = props => {
  const classes = useStyles()
  const {
    errors,
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
  } = props

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid item>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={errors.email && touched.email}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            error={errors.password && touched.password}
          />
        </Grid>
        <Grid item>
          <Button color="primary" type="submit">
            Log In
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withFormik(formik),
)

export default enhance(Login)
