import { LOGIN_SUCCEEDED } from '../actions/login.actions'

export const initial = { jwt: undefined }

const authentication = (state = initial, action) => {
  const { type } = action

  switch (type) {
    case LOGIN_SUCCEEDED: {
      const {
        payload: { jwt },
      } = action

      return {
        ...state,
        jwt,
      }
    }

    default: {
      return state
    }
  }
}

export default authentication
