import { applyMiddleware, createStore, combineReducers } from 'redux'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import { composeWithDevTools } from 'redux-devtools-extension'

import { authentication } from './reducers'

import errorMiddleware from './middlewares/error.middleware'
import { combinedEpics } from './epics'
import normalizeMiddleware from './middlewares/normalize.middleware'
import parseMiddleware from './middlewares/parse.middleware'

export const reducer = combineReducers({ authentication })
const epicMiddleware = createEpicMiddleware()
const middlewares = [errorMiddleware, epicMiddleware, parseMiddleware]

/*
 * the normalize-middleware has to be first because it makes sure subsequent
 * elements of the pipeline don't get access errors on badly formatted actions
 * */
const middleware = composeWithDevTools(
  applyMiddleware(normalizeMiddleware, ...middlewares),
)

const store = createStore(reducer, {}, middleware)

epicMiddleware.run(combinedEpics)

export default store
