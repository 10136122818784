const parseMiddleware = store => next => action => {
  const { meta, payload } = action

  if (meta.response) {
    return next({
      ...action,
      payload: JSON.parse(payload),
    })
  }

  return next(action)
}

export default parseMiddleware
